@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400&display=swap');

* {
  margin: 0;
  padding: 0;
}
/* Default styles for the container */
.custom-container {
  width: 100%; /* Full width by default */
  padding: 20px; /* Add some padding for content */
  box-sizing: border-box; /* Include padding and border in the width */

  /* Optional: Apply a max-width for larger screens */
  max-width: 1200px;
  margin: 0 auto; /* Center the container horizontally */
}

/* Media query for screens equal to or greater than 768px (md) */
@media (min-width: 768px) {
  .custom-container {
    width: 80%; /* Adjust the width for medium-sized screens */
  }
}

/* Media query for screens equal to or greater than 992px (lg) */
@media (min-width: 992px) {
  .custom-container {
    width: 70%; /* Adjust the width for large screens */
  }
}

/* Media query for screens equal to or greater than 1200px (xl) */
@media (min-width: 1200px) {
  .custom-container {
    width: 1440px; /* Adjust the width for extra-large screens */
  }
}
