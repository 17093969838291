// color pellets
$grey: #00000073;
$theme: #2a4153;
$black: #000000;

.custom-card {
  cursor: pointer;
  .ant-card-body {
    .ant-card-meta {
      .ant-card-meta-description {
        .ant-space-item {
          .ant-space-item {
            .text-title {
              font-size: 16px;
              font-weight: 700;
              margin-bottom: 0;
            }
            .text-description {
              color: $grey;
              line-height: 20px;
              font-size: 12px;
            }
            .title-wrapper {
              flex-direction: column;
              .logo-wrapper {
                .ant-space-item {
                  display: flex;
                }
              }
              .tags-wrapper {
                font-size: 10px;
                border-radius: 50px;
              }
            }
            .logo-img {
              width: 25px;
              height: 25px;
              object-fit: contain;
            }
          }
        }
      }
    }
  }
  .ant-card-cover {
    position: relative;
    .ant-skeleton {
      width: 100%;
      .ant-skeleton-image {
        width: 100%;
        height: 150px;
      }
    }
  }
  .float-lock {
    position: absolute;
    bottom: 8px;
    right: 8px;
    width: auto;
  }
  .card-image {
    height: auto;
    object-fit: contain;
    width: 100%;
  }
}
.filter-tabs {
  .ant-tabs-ink-bar {
    background: $theme;
  }
  .ant-tabs-nav {
    margin-bottom: 50px;
    .ant-tabs-tab {
      .tabs-title {
        font-size: 16px;
        color: $black;
      }
      &.ant-tabs-tab-active {
        .tabs-title {
          font-size: 16px;
          color: $theme;
        }
      }
    }
  }
}
