$off-white: #f5f5f5;
$off-black: #00000040;
$light-grey: #cecece;
$med-grey: #e2e2e2;
$med-black: #0000008a;
$theme-color: #2a4153;

.login-form {
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  padding: 30px;
  background: $theme-color;
  border-radius: 5px;
  box-shadow: 0px 4px 12px 0px $off-black;
  border: 1px solid $light-grey;
  width: 345px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  @media (max-width: 467px) {
    width: 240px;
  }
  .text-login {
    text-align: center;
  }
  .btn-login {
    display: flex;
    column-gap: 10px;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    line-height: 23px;
    text-align: left;
    border: 1px solid $med-grey;
    border-radius: 10px;
    border: 1px solid $med-grey;
    width: 100%;
    span {
      color: $med-black;
    }
  }
}
