$theme-color: #2a4153;
header {
  background-color: $theme-color;
  .custom-container {
    display: flex;
    justify-content: space-between;
    .logo {
      cursor: pointer;
    }
  }
}
