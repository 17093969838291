$theme-color: #2a4153;
$white: #ffffff;

.footer-wrapper {
  min-height: 100px;
  background: $theme-color;
  display: flex;
  justify-content: center;
  align-items: center;
  .text-description {
    text-align: center;
    color: $white;
    margin-bottom: 0;
    font-size: 16px;
  }
}
.about-us-wrapper,
.contact-us-wrapper {
  .text-header {
    color: $white;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }
  .text-description {
    color: $white;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }
}

.contact-us-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  @media (max-width: 767px) {
    align-items: flex-start;
  }
  a {
    text-decoration: none;
    color: inherit;
    font-size: inherit;
    margin: 0 0 0 5px;
  }
}
